import { createStore } from "vuex";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc2VlZmphd2FpZDEyM0BnbWFpbC5jb20iLCJqdGkiOiIzM2VkNTE1Ny1hNDJiLTQ4NzEtOWRjOS1lMjIwMzMxM2I2YzUiLCJleHAiOjE3NjQ4MzA2OTksImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.VRcUs_GGEnlsDNn6WzwF920wc45JnQtzmyB2doZN8WU",
    articles: [],
    news: [],
    slides: [],
  },
  getters: {},
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setArticles(state, payload) {
      state.articles = payload;
    },
    setNews(state, payload) {
      state.news = payload;
    },
    setSlides(state, payload) {
      state.slides = payload;
    },
  },
  actions: {},
  modules: {},
});
